//@import "@xpo-ltl/ngx-ltl-core/theming/xpo-internal";

/* You can add global styles to this file, and also import other style files */
.xpo-Header {
  background: white !important;
}

h1 {
}

.mat-mdc-form-field {
}

a {
  cursor: pointer;
}

